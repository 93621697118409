<template>
  <div>
    <h3 style="margin-bottom: 20px">Entity Choices</h3>
    <el-row>
      <el-col :span="12" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="User can add new data"
          placement="right"
        >
          <el-checkbox v-model="field.allow_quick_add">
            <span> Allow to add new data </span>
          </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="12" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="If an entity has only one piece of data, it will be selected."
          placement="left"
        >
          <el-checkbox v-model="field.auto_select_one_data">
            Auto select if entity has only 1 data
          </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="12" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="User can select multiple options"
          placement="right"
        >
          <el-checkbox
            :disabled="checkEntityVariable"
            v-model="field.allow_multiple"
          >
            Allow multiple data
          </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="12" v-if="field.allow_multiple" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="All entity options will be auto selected"
          placement="right"
        >
          <el-checkbox v-model="field.auto_complete"> Auto fill </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="12" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="Entity selection will be shown as a link. Users can click it to view detailed entity data, including data table rows."
          placement="right"
        >
          <el-checkbox v-model="field.detailed_view">
            Show as detailed view link
          </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="12" v-if="field.detailed_view" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="In detailed we get only entity data not data tables rows"
          placement="right"
        >
          <el-checkbox v-model="field.show_only_totals">
            Show only totals
          </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="12" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="Include an ALL option to select all entity options at once."
          placement="right"
        >
          <el-checkbox v-model="field.show_all_option">
            Show ALL option
          </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="12" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="From this, we can show an alert when there is no data."
          placement="right"
          >{{ shouldShowCheckbox }}
          <el-checkbox
            v-if="shouldShowCheckbox"
            v-model="field.show_alert_entity"
          >
            <span> Display an alert if there is no data available </span>
          </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="18">
        <div
          class="input-container"
          v-if="field.show_alert_entity && shouldShowCheckbox"
          style="margin-bottom: 10px; display: flex; align-items: center"
        >
          <span> Alert Message: </span>
          <el-input
            v-model="field.alert_message_store"
            placeholder="Enter your message"
            style="flex: 1"
          ></el-input>
        </div>
      </el-col>

      <el-col :span="12" class="checkbox-col">
        <el-tooltip
          effect="dark"
          content="Getting entity data and auto-selecting, etc., will also execute in view mode."
          placement="right"
        >
          <el-checkbox v-model="field.always_execute">
            Always Execute
          </el-checkbox>
        </el-tooltip>
      </el-col>
      <el-col :span="12" class="checkbox-col">
        <div style="col-align">
          <label>Sort data by</label>
          <el-select
            v-model="field.entity_sort"
            size="mini"
            placeholder="Sorting order"
            clearable
          >
            <template
              v-for="(option, index) in getSelectedEntityCountFields([
                'DATE',
                'NUMBER',
                'CURRENCY',
                'SELECT',
              ])"
            >
              <el-option
                :value="'asc/' + option.template_id + '#' + option.key"
                :label="option.label"
                :key="index + '_field_ase'"
              >
                <span style="float: left">{{ option.label }}</span>
                <span style="float: right">Asc</span>
              </el-option>
              <el-option
                :value="'des/' + option.template_id + '#' + option.key"
                :label="option.label"
                :key="index + '_field_dis'"
              >
                <span style="float: left">{{ option.label }}</span>
                <span style="float: right">Des</span>
              </el-option>
            </template>
          </el-select>
        </div>
      </el-col>
      <el-col :span="12" class="checkbox-col">
        <div>
          <label>Select primary fields</label>
          <el-select
            v-model="field.primary_fields"
            no-data-text="Primary fields"
            filterable
            default-first-option
            multiple
            collapse-tags
          >
            <el-option
              v-for="(entity, index) of allEntityFields || []"
              :value="entity.key"
              :key="index + '' + entity.key"
              :label="entity.label"
            >
              <span style="float: left">{{ entity.label }}</span>
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="24" style="margin-top: 20px" class="checkbox-col">
        <h2>Entity Filters</h2>
        <EntityFilters :field="field" :fieldsData="fieldsData"></EntityFilters>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "templates-formComponents-Entity-advanced-settings",
  props: ["field", "fieldsData", "allEntityFields"],
  data() {
    return {
      openFiltersDialogComponent: false,
      show_alert_entity: false,
    };
  },
  mounted() {},
  components: {
    EntityFilters: () => import("./EntityFilters"),
  },
  computed: {
    shouldShowCheckbox() {
      return (this.field.filters || []).find((filter) => {
        if (
          filter.data_source == "self_field" ||
          filter.data_source == "TEMPLATE"
        ) {
          return true;
        }
      })
        ? true
        : false;
    },
    checkEntityVariable() {
      if (this.fieldsData && this.fieldsData.length > 0) {
        let variables = this.fieldsData.filter(
          (e) =>
            e.relationship_key == this.field.key && e.inputType != "DATA_TABLE"
        );
        return variables.length ? true : false;
      }
      return false;
    },
  },
  methods: {
    getSelectedEntityCountFields(types) {
      let newFields = JSON.parse(JSON.stringify(this.allEntityFields));
      return (newFields || [])
        .filter(
          (e) =>
            types.indexOf(e.inputType) != -1 ||
            (e.inputType == "FORMULA" && types.indexOf(e.result_type) != -1) ||
            (e.inputType == "AGGREGATE_FUNCTION" &&
              (types.indexOf("NUMBER") != -1 ||
                types.indexOf("CURRENCY") != -1))
        )
        .map((e) => {
          if (e?.key && e.key.includes("#")) {
            e.key = e.key.split("#")[1];
          }
          return e;
        });
    },
    openFilterModel() {
      this.openFiltersDialogComponent = true;
    },
    closeFiltersDialogComponent() {
      this.openFiltersDialogComponent = false;
    },
  },
};
</script>

<style lang="scss">
.col-align {
  display: flex;
  flex-direction: column !important;
}
.checkbox-col {
  margin-bottom: 10px; // Adjust the value as needed for the desired gap
}
</style>
